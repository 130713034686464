import React, { useRef } from 'react';
import './Home.css';
import FeaturedEvents from '../components/FeaturedEvents';
import FAQSection from '../components/FAQSection';
import FeaturedSections from '../components/FeaturedSections';
import BackToTopButton from '../components/BackToTopButton';
import ContactUs from '../components/ContactUs';


function Home() {
  const faqs = [
    {
      question: 'Hvordan bliver jeg medlem?',
      answer: 'Alle grundejere er automatisk medlemmer af Kildeskoven foreningen.',
    },
    {
      question: 'Hvordan tilmelder jeg mig arrangementer?',
      answer: 'Arrangementer kan tilmeldes via vores hjemmeside eller kontakt bestyrelsen.',
    },
  ];

  const introRef = useRef(null);

  const scrollToIntro = () => {
    introRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="home-container">
      <section className="hero">
        <div className="hero-content">
          <h1>Velkommen til Grundejerforeningen Kildeskoven</h1>
          <p>Et trygt, grønt og levende fællesskab for alle beboere</p>
          <button className="cta-button" onClick={scrollToIntro}>
            Læs Mere
          </button>
        </div>
      </section>

      <section className="intro" ref={introRef}>
        <h2>Om Vores Fællesskab</h2>
        <p>Kildeskoven er et område, hvor naturen møder byen.</p>
      </section>

      <FeaturedSections />
      <FeaturedEvents />
      <FAQSection faqs={faqs} />
      <ContactUs
        title="Bliv en del af vores fællesskab"
        description="Vil du lære mere om Kildeskoven? Kontakt os i dag!"
        linkText="Kontakt Os"
        linkHref="/kontakt"
      />

      <BackToTopButton />
    </div>
  );
}

export default Home;
