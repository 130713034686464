import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import './Navbar.css';

function Navbar() {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const location = useLocation();

  // Close menu on route change
  useEffect(() => {
    setMenuOpen(false);
    setDropdownOpen(false);
  }, [location.pathname]);

  const toggleMenu = () => setMenuOpen(!isMenuOpen);

  const toggleDropdown = () => setDropdownOpen(!isDropdownOpen);

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <NavLink to="/" className="navbar-logo">
          Kildeskoven
        </NavLink>

        <button
          className={`hamburger ${isMenuOpen ? 'open' : ''}`}
          onClick={toggleMenu}
          aria-label="Toggle menu"
          aria-expanded={isMenuOpen}
        >
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </button>

        <ul className={`navbar-links ${isMenuOpen ? 'open' : ''}`}>
          <li>
            <NavLink to="/" className={({ isActive }) => (isActive ? 'active-link' : '')}>
              Forside
            </NavLink>
          </li>
          <li
            className={`dropdown ${isDropdownOpen ? 'open' : ''}`}
            onMouseEnter={() => setDropdownOpen(true)}
            onMouseLeave={() => setDropdownOpen(false)}
          >
            <button
              className="dropdown-toggle"
              onClick={toggleDropdown}
              aria-expanded={isDropdownOpen}
              aria-haspopup="true"
            >
              Grundejerforening
            </button>
            <div className="dropdown-menu">
              <NavLink to="/vedtekter" className="dropdown-item">
                Vedtekter
              </NavLink>
              <NavLink to="/regler" className="dropdown-item">
                Regler
              </NavLink>
              <NavLink to="/referater" className="dropdown-item">
                Referater
              </NavLink>
              <NavLink to="/budget" className="dropdown-item">
                Budget
              </NavLink>
            </div>
          </li>
          <li>
            <NavLink to="/Området" className={({ isActive }) => (isActive ? 'active-link' : '')}>
              Om Området
            </NavLink>
          </li>
          <li>
            <NavLink to="/events" className={({ isActive }) => (isActive ? 'active-link' : '')}>
              Events
            </NavLink>
          </li>
          <li>
            <NavLink to="/om-os" className={({ isActive }) => (isActive ? 'active-link' : '')}>
              Om Os
            </NavLink>
          </li>
          <li>
            <NavLink to="/Nyheder" className={({ isActive }) => (isActive ? 'active-link' : '')}>
              Nyheder
            </NavLink>
          </li>
          <li>
            <NavLink to="/kontakt" className={({ isActive }) => (isActive ? 'active-link' : '')}>
              Kontakt
            </NavLink>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;
