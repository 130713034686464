// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Omraadet from './pages/Omraadet';
import OmOs from './pages/OmOs';
import Events from './pages/Events';
import Nyheder from './pages/Nyheder';
import Kontakt from './pages/Kontakt';
import Layout from './components/Layout';
import Galleri from './pages/Galleri';
import Vedtekter from './pages/Vedtekter';


function App() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Området" element={<Omraadet />} />
          <Route path="/om-os" element={<OmOs />} />
          <Route path="/events" element={<Events />} />
          <Route path="/nyheder" element={<Nyheder />} />
          <Route path="/kontakt" element={<Kontakt />} />
          <Route path="/galleri" element={<Galleri />} />
          <Route path="/vedtekter" element={<Vedtekter />} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
