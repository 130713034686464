import React from 'react';
import './ContactUs.css';

function ContactUs({ title, description, linkText, linkHref }) {
  return (
    <section className="contact-us-section">
      <h2>{title}</h2>
      <p>{description}</p>
      <a href={linkHref} className="contact-us-button">
        {linkText}
      </a>
    </section>
  );
}

export default ContactUs;
