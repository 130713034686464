import React, { useState } from 'react';
import './FeaturedEvents.css';

function FeaturedEvents() {
  const events = [
    {
      title: 'Sommerfest i Parken',
      date: '15. Juni 2024',
      description: 'Kom og nyd en hyggelig dag i parken med grill, lege for børnene og live musik.',
      image: 'https://via.placeholder.com/300x200?text=Sommerfest'
    },
    {
      title: 'Fælles Havearbejdsdag',
      date: '29. Juni 2024',
      description: 'Vi mødes til en fælles indsats for at holde vores grønne områder pæne.',
      image: 'https://via.placeholder.com/300x200?text=Havearbejdsdag'
    },
    {
      title: 'Loppemarked i Bytorvet',
      date: '12. Juli 2024',
      description: 'Ryd op i loftet og sæt dine ting til salg, eller kom og gør et godt fund.',
      image: 'https://via.placeholder.com/300x200?text=Loppemarked'
    },
    {
      title: 'Skt. Hans Aften',
      date: '23. Juni 2024',
      description: 'Fælles bål, sang, og hygge. Medbring en stol eller tæppe.',
      image: 'https://via.placeholder.com/300x200?text=Skt.Hans'
    }
  ];

  // We'll show only 2 events at first
  const [showAll, setShowAll] = useState(false);

  const displayedEvents = showAll ? events : events.slice(0, 2);

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  return (
    <section className="featured-events">
      <h2>Fremhævede Begivenheder</h2>
      <div className="events-grid">
        {displayedEvents.map((event, index) => (
          <div key={index} className="event-card">
            <img src={event.image} alt={event.title} />
            <div className="event-info">
              <h3>{event.title}</h3>
              <p className="event-date"><strong>{event.date}</strong></p>
              <p>{event.description}</p>
            </div>
          </div>
        ))}
      </div>
      <button className="toggle-button" onClick={toggleShowAll}>
        {showAll ? 'Vis færre' : 'Vis alle'}
      </button>
    </section>
  );
}

export default FeaturedEvents;
