import React, { useState, useEffect, useRef } from 'react';
import './Kontakt.css';
import BackToTopButton from '../components/BackToTopButton';


function Kontakt() {
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const [errors, setErrors] = useState({});
  const [submitStatus, setSubmitStatus] = useState(null);
  const [faqOpenIndex, setFaqOpenIndex] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    setErrors(prev => ({ ...prev, [name]: '' }));
  };

  const validate = () => {
    let newErrors = {};
    if (!formData.name.trim()) newErrors.name = 'Indtast venligst dit navn.';
    if (!formData.email.trim()) {
      newErrors.email = 'Indtast venligst din e-mail.';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Indtast venligst en gyldig e-mail.';
    }
    if (!formData.message.trim()) newErrors.message = 'Indtast venligst din besked.';
    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    // Simulate submission
    setTimeout(() => {
      if (Math.random() > 0.2) {
        setSubmitStatus('success');
        setFormData({ name: '', email: '', message: '' });
      } else {
        setSubmitStatus('error');
      }
    }, 1000);
  };

  const faqs = [
    {
      question: 'Hvor hurtigt svarer I på beskeder?',
      answer: 'Vi bestræber os på at svare indenfor 24 timer på hverdage.'
    },
    {
      question: 'Kan jeg besøge jer personligt?',
      answer: 'Ja, vores kontor er åbent efter aftale. Kontakt os for at aftale et tidspunkt.'
    },
    {
      question: 'Hvilke arrangementer kan jeg forvente i fremtiden?',
      answer: 'Vi opdaterer løbende vores begivenhedskalender på hjemmesiden under "Events".'
    }
  ];

  const toggleFAQ = (index) => {
    setFaqOpenIndex(faqOpenIndex === index ? null : index);
  };

  return (
    <div className="kontakt-container">
      <header className="kontakt-header">
        <h1>Kontakt Os</h1>
        <p>
          Har du spørgsmål eller brug for hjælp? Udfyld formularen, ring, eller besøg os. Vi er her for at hjælpe!
        </p>
      </header>

      <div className="kontakt-content">
        <div className="kontakt-info">
          <h2>Kontaktinformation</h2>
          <p><strong>Adresse:</strong> Kildeskoven 123, 2800 Lyngby, Danmark</p>
          <p><strong>Telefon:</strong> +45 12 34 56 78</p>
          <p><strong>E-mail:</strong> info@kildeskoven.dk</p>

         
          <p><em>Vi bestræber os på at svare inden for 24 timer.</em></p>

          <div className="kontakt-map">
            <h3>Vores Placering</h3>
            <iframe
              title="Kildeskoven Location"
              src= "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2146.7224494725065!2d11.788910034887703!3d55.470202900000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46528f516a680fef%3A0x8da098e961dbbda!2sGrundejerforeningen%20Kildeskoven!5e1!3m2!1sda!2sdk!4v1733612625953!5m2!1sda!2sdk"
              width="100%"
              height="200"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
            ></iframe>
          </div>
      
          

         

        </div>

        <div className="kontakt-form-container">
          <h2>Send os en besked</h2>
          <form onSubmit={handleSubmit} className="kontakt-form">
            <div className="form-group">
              <label htmlFor="name">Navn</label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Dit navn"
                value={formData.name}
                onChange={handleChange}
                className={errors.name ? 'error' : ''}
              />
              {errors.name && <span className="error-text">{errors.name}</span>}
            </div>
            
            <div className="form-group">
              <label htmlFor="email">E-mail</label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Din e-mail"
                value={formData.email}
                onChange={handleChange}
                className={errors.email ? 'error' : ''}
              />
              {errors.email && <span className="error-text">{errors.email}</span>}
            </div>
            
            <div className="form-group">
              <label htmlFor="message">Besked</label>
              <textarea
                id="message"
                name="message"
                placeholder="Din besked..."
                value={formData.message}
                onChange={handleChange}
                className={errors.message ? 'error' : ''}
              ></textarea>
              {errors.message && <span className="error-text">{errors.message}</span>}
            </div>

            <button type="submit" className="submit-btn">Send</button>

            {submitStatus === 'success' && (
              <p className="success-message">Tak for din besked! Vi vender tilbage hurtigst muligt.</p>
            )}
            {submitStatus === 'error' && (
              <p className="error-message">Noget gik galt. Prøv igen senere.</p>
            )}
          </form>
        </div>
      </div>

     

      <div className="faq-section">
        <h2>Ofte Stillede Spørgsmål</h2>
        <div className="faq-list">
          {faqs.map((faq, index) => (
            <div className="faq-item" key={index}>
              <button className="faq-question" onClick={() => toggleFAQ(index)}>
                {faq.question}
                <span className="faq-icon">{faqOpenIndex === index ? '–' : '+'}</span>
              </button>
              {faqOpenIndex === index && (
                <div className="faq-answer">
                  <p>{faq.answer}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>

      <BackToTopButton />
    </div>

    

  );

}

export default Kontakt;
