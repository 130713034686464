import React from 'react';
import './Vedtekter.css';
import FAQSection from '../components/FAQSection'; // Import your existing FAQ component
import BackToTopButton from '../components/BackToTopButton'; // Import your existing BackToTopButton component
import ContactUs from '../components/ContactUs';


function Vedtekter() {
  const documents = [
    { id: 1, title: 'Vedtekter for 2023', description: 'Seneste opdateringer af vedtekterne.', link: '/downloads/vedtekter-2023.pdf' },
    { id: 2, title: 'Vedtekter for 2022', description: 'Tidligere opdateringer og ændringer.', link: '/downloads/vedtekter-2022.pdf' },
    { id: 3, title: 'Vedtekter for 2021', description: 'Historiske vedtekter.', link: '/downloads/vedtekter-2021.pdf' },
  ];

  const faqs = [
    {
      question: 'Hvor finder jeg vedtægterne?',
      answer: 'Alle vedtægter kan downloades fra vores hjemmeside under sektionen "Dokumenter".',
    },
    {
      question: 'Hvad er formålet med vedtægterne?',
      answer: 'Vedtægterne definerer reglerne for foreningen og dens beboere.',
    },
  ];
  
  return (
    <div className="vedtekter-container">
      {/* Hero Section */}
      <header className="vedtekter-hero">
        <div className="hero-overlay">
          <h1>Vedtekter</h1>
          <p>Find og download de seneste og tidligere vedtekter for Grundejerforeningen Kildeskoven.</p>
        </div>
      </header>

      {/* Text Section */}
      <section className="vedtekter-text">
        <h2>Om Vedtekter</h2>
        <p>
          Vedtekterne er grundlaget for, hvordan Grundejerforeningen Kildeskoven drives. De fastsætter regler
          og retningslinjer, som beboere og bestyrelsen skal følge. Her finder du opdaterede vedtekter for
          de seneste år, som alle er tilgængelige for download.
        </p>
      </section>

      {/* Document List */}
      <section className="vedtekter-documents">
        <h2>Tilgængelige Dokumenter</h2>
        <div className="document-grid">
          {documents.map((doc) => (
            <div key={doc.id} className="document-card">
              <div className="document-icon">
                <i className="fas fa-file-pdf"></i>
              </div>
              <h2>{doc.title}</h2>
              <p>{doc.description}</p>
              <a href={doc.link} target="_blank" rel="noopener noreferrer" className="download-button">
                Download
              </a>
            </div>
          ))}
        </div>
      </section>

      {/* FAQ Section */}
      <FAQSection faqs={faqs} title="Ofte Stillede Spørgsmål
" />      

      

      <ContactUs
        title="Har du spørgsmål om vedtægterne?"
        description="Deltag i vores møder for at diskutere vedtekterne og deres betydning for foreningen.
          Kontakt os for yderligere information."
        linkText="Få Mere Info"
        linkHref="/kontakt"
      />
      <BackToTopButton />
    </div>
  );
}

export default Vedtekter;
