import React, { useState } from 'react';
import './Omraadet.css'; // Link to the CSS file

// Import images
import img1 from '../assets/images/1.jpg';
import img2 from '../assets/images/2.jpg';
import img3 from '../assets/images/3.jpg';

function Omraadet() {
  const images = [
    { src: img1, alt: 'Placeholder 1', caption: 'Billede 1 fra Området' },
    { src: img2, alt: 'Placeholder 2', caption: 'Billede 2 fra Området' },
    { src: img3, alt: 'Placeholder 3', caption: 'Billede 3 fra Området' },
  ];

  const [currentIndex, setCurrentIndex] = useState(null);

  const openLightbox = (index) => {
    setCurrentIndex(index);
  };

  const closeLightbox = () => {
    setCurrentIndex(null);
  };

  const showPrev = (e) => {
    e.stopPropagation();
    setCurrentIndex((currentIndex - 1 + images.length) % images.length);
  };

  const showNext = (e) => {
    e.stopPropagation();
    setCurrentIndex((currentIndex + 1) % images.length);
  };

  return (
    <div className="omraadet-container">
      {/* Hero Section */}
      <section className="omraadet-hero">
        <div className="hero-overlay">
          <h1>Området</h1>
          <p>Velkommen til et område, hvor moderne livsstil møder naturens skønhed.</p>
        </div>
      </section>

      {/* Intro Section */}
      <section className="intro-section">
        <div className="intro-content">
          <h2>Om Området</h2>
          <p>
            Velkommen til Kildeskoven, et naturskønt boligområde, hvor moderne arkitektur møder fredfyldte omgivelser. Her er plads til både ro og aktivt udeliv, omgivet af frodige skove, åbne marker og stisystemer, der binder området sammen.
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus vel neque eget purus gravida tincidunt. Nullam malesuada ligula ac libero cursus.
          </p>
        </div>
      </section>

      {/* Image Gallery Section */}
      <section className="gallery-section">
        <h2>Billeder fra Området</h2>
        <div className="gallery-grid">
          {images.map((img, index) => (
            <div
              key={index}
              className="gallery-item"
              onClick={() => openLightbox(index)}
            >
              <img src={img.src} alt={img.alt} />
            </div>
          ))}
        </div>
      </section>

      {currentIndex !== null && (
        <div className="lightbox" onClick={closeLightbox}>
          <div className="lightbox-content" onClick={(e) => e.stopPropagation()}>
            <button className="lightbox-close" onClick={closeLightbox}>
              &times;
            </button>
            <button className="lightbox-nav left" onClick={showPrev}>
              ‹
            </button>
            <div className="lightbox-main">
              <img
                src={images[currentIndex].src}
                alt={images[currentIndex].alt}
                className="lightbox-image"
              />
              <div className="lightbox-caption">{images[currentIndex].caption}</div>
            </div>
            <button className="lightbox-nav right" onClick={showNext}>
              ›
            </button>
          </div>
        </div>
      )}

      {/* Features Section */}
      <section className="features-section">
        <h2>Områdets Funktioner</h2>
        <div className="features-grid">
          <div className="feature">
            <h3>Et Område Fyldt Med Muligheder</h3>
            <p>
              Blandt områdets mange kvaliteter finder du grønne fællesarealer og rekreative faciliteter.
            </p>
          </div>
          <div className="feature">
            <h3>Oplev Det Lokale Liv</h3>
            <p>
              Med kort afstand til både skoler, indkøbsmuligheder og kulturtilbud kan du nyde det bedste af to verdener.
            </p>
          </div>
          <div className="feature">
            <h3>Bo Tæt På Naturen</h3>
            <p>
              Området byder på unikke landskaber og biodiversitet, som inviterer til udendørs aktiviteter hele året rundt.
            </p>
          </div>
        </div>
      </section>

      {/* Detailed Sections */}
      <section className="detailed-sections">
        <h2>Detaljer om Området</h2>
        <div className="details-grid">
          <div className="detail">
            <h3>Grønne Kiler</h3>
            <p>
              De grønne kiler skaber åbne, landskabelige rum, som integrerer med den nye bebyggelse.
            </p>
          </div>
          <div className="detail">
            <h3>Stisystemer</h3>
            <p>
              Lokalplanen inkluderer et omfattende netværk af stier for både fodgængere og cyklister.
            </p>
          </div>
          <div className="detail">
            <h3>Grundvandsbeskyttelse</h3>
            <p>
              Området ligger i en grundvandsbeskyttelseszone, hvilket betyder, at der er taget særlige hensyn for at beskytte grundvandet.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Omraadet;
