import React from 'react';
import { NavLink } from 'react-router-dom';
import './FeaturedSections.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUsers,
  faTree,
  faCalendarAlt,
  faNewspaper,
  faEnvelope,
  faImages,
  faGavel,
  faMapMarkedAlt,
} from '@fortawesome/free-solid-svg-icons';

function FeaturedSections() {
  const sections = [
    {
      title: 'Om Os',
      description: 'Lær mere om vores historie, formål og bestyrelse.',
      link: '/om-os',
      icon: faUsers, // Group icon for community
    },
    {
      title: 'Området',
      description: 'Oplev de grønne områder, stier og legepladser.',
      link: '/omraadet',
      icon: faTree, // Tree icon for nature
    },
    {
      title: 'Events',
      description: 'Hold dig opdateret på kommende begivenheder.',
      link: '/events',
      icon: faCalendarAlt, // Calendar icon for events
    },
    {
      title: 'Nyheder',
      description: 'Læs de seneste opdateringer og meddelelser.',
      link: '/nyheder',
      icon: faNewspaper, // Newspaper icon for news
    },
    {
      title: 'Kontakt',
      description: 'Har du spørgsmål? Kontakt vores bestyrelse.',
      link: '/kontakt',
      icon: faEnvelope, // Envelope icon for contact
    },
    {
      title: 'Galleri',
      description: 'Se billeder fra området og tidligere arrangementer.',
      link: '/galleri',
      icon: faImages, // Images icon for gallery
    },
    {
      title: 'Grundejerforening',
      description: 'Find information om vedtægter og aktiviteter.',
      link: '/grundejerforeningen',
      icon: faGavel, // Gavel icon for governance
    },
    {
      title: 'Lokalplan',
      description: 'Se detaljer om lokalplanen for området.',
      link: '/lokalplan',
      icon: faMapMarkedAlt, // Map icon for local planning
    },
  ];
  return (
    <div className="featured-sections">
      <h2>Udforsk Vores Områder</h2>
      <div className="sections-grid">
        {sections.map((section, index) => (
          <a key={index} href={section.link} className="section-card">
            <div className="icon">
              <FontAwesomeIcon icon={section.icon} />
            </div>
            <h3>{section.title}</h3>
            <p>{section.description}</p>
          </a>
        ))}
      </div>
    </div>
  );
}

export default FeaturedSections;
