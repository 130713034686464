import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-left">
          <h3>Grundejerforeningen Kildeskoven</h3>
          <p>
            Et trygt og grønt fællesskab for beboere i Kildeskoven. Vi arbejder for at fremme trivsel og fællesskab.
          </p>
        </div>

        <div className="footer-center">
          <h4>Hurtige Links</h4>
          <ul>
            <li><a href="/om-os">Om Os</a></li>
            <li><a href="/vedtekter">Vedtekter</a></li>
            <li><a href="/events">Events</a></li>
            <li><a href="/kontakt">Kontakt</a></li>
          </ul>
        </div>

        <div className="footer-right">
          <h4>Kontakt Os</h4>
          <p>Email: <a href="mailto:info@kildeskoven.dk">info@kildeskoven.dk</a></p>
          <p>Telefon: +45 12 34 56 78</p>
        </div>
      </div>

      <div className="footer-bottom">
        <p>© {new Date().getFullYear()} Grundejerforeningen Kildeskoven. Alle rettigheder forbeholdes.</p>
      </div>
    </footer>
  );
}

export default Footer;
