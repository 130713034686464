import React from 'react';
import './OmOs.css';
import BackToTopButton from '../components/BackToTopButton';
import FAQSection from '../components/FAQSection';
import ContactUs from '../components/ContactUs';


function OmOs() {
  const faqs = [
    {
      question: 'Hvad er Kildeskoven?',
      answer: 'Kildeskoven er en forening baseret på fællesskab og bæredygtighed.',
    },
    {
      question: 'Hvordan kan jeg bidrage?',
      answer: 'Du kan bidrage ved at deltage i arrangementer og fællesprojekter.',
    },
  ];

  return (
    <div className="om-os-container">
      {/* Hero Section */}
      <section className="om-os-hero">
        <div className="hero-overlay">
          <h1>Velkommen til Kildeskoven</h1>
          <p>
            Et fællesskab bygget på tillid, innovation og sammenhold, hvor natur og trivsel går hånd i hånd.
          </p>
        </div>
      </section>

      {/* Mission, Vision, and Values */}
      <section className="mvv-section">
        <h2>Hvad vi står for</h2>
        <div className="mvv-grid">
          <div className="mvv-card">
            <h3>Mission</h3>
            <p>
              At skabe et trygt og harmonisk miljø for beboerne med fokus på natur og fællesskab.
            </p>
          </div>
          <div className="mvv-card">
            <h3>Vision</h3>
            <p>
              At være en førende model for bæredygtige og inkluderende boligfællesskaber.
            </p>
          </div>
          <div className="mvv-card">
            <h3>Værdier</h3>
            <p>
              Samarbejde, innovation og respekt er kernen i alt, vi gør.
            </p>
          </div>
        </div>
      </section>
      
      <section className="about-kildeskoven">
  <div className="about-content">
    <h2>Om Kildeskoven</h2>
    <p>
      Kildeskoven er et område, hvor natur og fællesskab går hånd i hånd. 
      Med velholdte stier, smukke grønne områder og en stærk fællesskabsånd 
      arbejder vi hver dag for at gøre Kildeskoven til et trygt og inspirerende sted at bo.
    </p>
    <p>
      Her i Kildeskoven prioriterer vi bæredygtighed og innovation, samtidig med 
      at vi skaber et rummeligt miljø for både børn, voksne og seniorer. Vores 
      arrangementer og aktiviteter er designet til at samle mennesker og skabe minder.
    </p>
  </div>
</section>

      {/* Community Highlights */}
      <section className="highlights-section">
        <h2>Højdepunkter fra Kildeskoven</h2>
        <div className="highlights-grid">
          <div className="highlight-card">
            <h3>50+</h3>
            <p>Årlige arrangementer for hele fællesskabet.</p>
          </div>
          <div className="highlight-card">
            <h3>20 km</h3>
            <p>Velholdte stier og naturruter for alle aldre.</p>
          </div>
          <div className="highlight-card">
            <h3>100%</h3>
            <p>Bæredygtig energi i alle fællesområder.</p>
          </div>
          <div className="highlight-card">
            <h3>30+</h3>
            <p>Legepladser og parker dedikeret til familier.</p>
          </div>
        </div>
      </section>


      <FAQSection faqs={faqs} title="Ofte Stillede Spørgsmål" />

      <ContactUs
        title="Er du nysgerrig på vores værdier?"
        description="Kontakt os for at lære mere om vores vision og mission."
        linkText="Kontakt Os"
        linkHref="/kontakt"
      />
     
      <BackToTopButton />

    </div>
  );
}

export default OmOs;
