import React from 'react';
import '../App.css'; // Correct relative path
function Events() {
  return (
    <div className="content">
      <h1>Events</h1>
      <p>Check out upcoming community events here on the Events page.</p>
    </div>
  );
}

export default Events;
