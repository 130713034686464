import React, { useState, useEffect, useRef } from 'react';
import './Galleri.css';
import BackToTopButton from '../components/BackToTopButton';


// Import your 7 images
import img1 from '../assets/images/1.jpg';
import img2 from '../assets/images/2.jpg';
import img3 from '../assets/images/3.jpg';
import img4 from '../assets/images/4.jpg';
import img5 from '../assets/images/5.jpg';
import img6 from '../assets/images/6.jpg';
import img7 from '../assets/images/7.jpg';

function Galleri() {
  // Array of images with categories
  const images = [
    { src: img1, alt: 'Event 1', caption: 'Sommerfest i Parken', category: 'Events' },
    { src: img2, alt: 'Event 2', caption: 'Fælles Havearbejdsdag', category: 'Events' },
    { src: img3, alt: 'Event 3', caption: 'Loppemarked i Bytorvet', category: 'Events' },
    { src: img4, alt: 'Nature 1', caption: 'Grønne områder', category: 'Natur' },
    { src: img5, alt: 'Nature 2', caption: 'Velholdte stier', category: 'Natur' },
    { src: img6, alt: 'Legeplads', caption: 'Børnelegeplads', category: 'Fællesskab' },
    { src: img7, alt: 'Bytorv', caption: 'Hyggeligt bytorv', category: 'Fællesskab' }
  ];

  const [currentIndex, setCurrentIndex] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('Alle');
  const [isPlaying, setIsPlaying] = useState(false); // Slideshow mode
  const slideshowIntervalRef = useRef(null);
  
  const isLightboxOpen = currentIndex !== null;

  const filteredImages = images.filter((img) => 
    selectedCategory === 'Alle' || img.category === selectedCategory
  );

  const openLightbox = (index) => {
    setCurrentIndex(index);
  };

  const closeLightbox = () => {
    setCurrentIndex(null);
    setIsPlaying(false);
  };

  const showPrev = (e) => {
    if (e) e.stopPropagation();
    setCurrentIndex((currentIndex - 1 + filteredImages.length) % filteredImages.length);
  };

  const showNext = (e) => {
    if (e) e.stopPropagation();
    setCurrentIndex((currentIndex + 1) % filteredImages.length);
  };

  // Keyboard navigation & slideshow toggle
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (!isLightboxOpen) return;
      if (e.key === 'Escape') {
        closeLightbox();
      } else if (e.key === 'ArrowLeft') {
        showPrev(e);
      } else if (e.key === 'ArrowRight') {
        showNext(e);
      } else if (e.key === ' ') {
        // Space toggles play/pause
        setIsPlaying((prev) => !prev);
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [isLightboxOpen, currentIndex, filteredImages]);

  // Slideshow functionality
  useEffect(() => {
    if (isPlaying) {
      slideshowIntervalRef.current = setInterval(() => {
        showNext();
      }, 3000); // 3 seconds per image
    } else {
      if (slideshowIntervalRef.current) clearInterval(slideshowIntervalRef.current);
    }
    return () => {
      if (slideshowIntervalRef.current) clearInterval(slideshowIntervalRef.current);
    };
  }, [isPlaying, currentIndex, filteredImages]);

  const toggleSlideshow = () => {
    setIsPlaying(!isPlaying);
  };

  const jumpToImage = (index, e) => {
    e.stopPropagation();
    setCurrentIndex(index);
    setIsPlaying(false); // Stop slideshow when manually navigating
  };

  return (
    <div className="galleri-container">
      <header className="galleri-header">
        <h1>Galleri</h1>
        <p>Udforsk øjeblikke fra vores fællesskab, begivenheder og smukke omgivelser.</p>
      </header>

      <div className="categories">
        {['Alle', 'Events', 'Natur', 'Fællesskab'].map((cat) => (
          <button
            key={cat}
            className={selectedCategory === cat ? 'active' : ''}
            onClick={() => setSelectedCategory(cat)}
          >
            {cat}
          </button>
        ))}
      </div>

      <div className="gallery-grid">
        {filteredImages.map((img, index) => (
          <div className="gallery-item" key={index} onClick={() => openLightbox(index)}>
            <img src={img.src} alt={img.alt} />
            <div className="gallery-item-overlay">
              <span className="gallery-item-caption">{img.caption}</span>
            </div>
          </div>
        ))}
      </div>

      {isLightboxOpen && filteredImages[currentIndex] && (
        <div className="lightbox" onClick={closeLightbox}>
          <div className="lightbox-content" onClick={(e) => e.stopPropagation()}>
            <button className="lightbox-close" onClick={closeLightbox}>&times;</button>
            <button className="lightbox-nav left" onClick={showPrev}>‹</button>
            
            <div className="lightbox-main">
              <img
                src={filteredImages[currentIndex].src}
                alt={filteredImages[currentIndex].alt}
                className="lightbox-image"
              />
              <div className="lightbox-caption">{filteredImages[currentIndex].caption}</div>
            </div>

            <button className="lightbox-nav right" onClick={showNext}>›</button>

            <div className="lightbox-footer">
              <button className="slideshow-toggle" onClick={toggleSlideshow}>
                {isPlaying ? 'Pause Slideshow' : 'Play Slideshow'}
              </button>
              <div className="thumbnails">
                {filteredImages.map((thumb, i) => (
                  <img
                    key={i}
                    src={thumb.src}
                    alt={thumb.alt}
                    className={`thumbnail ${i === currentIndex ? 'current' : ''}`}
                    onClick={(e) => jumpToImage(i, e)}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
      <BackToTopButton />

    </div>
  );
}

export default Galleri;
