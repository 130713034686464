import React, { useState, useEffect } from 'react';
import './Nyheder.css';
import BackToTopButton from '../components/BackToTopButton';

// Sample news data (In reality, you might fetch this from an API)
const initialNews = [
  {
    id: 1,
    title: 'Sommerfest for Beboere',
    category: 'Arrangementer',
    date: '2024-06-15',
    image: 'https://via.placeholder.com/400x250?text=Sommerfest',
    summary: 'Kom til årets sommerfest i parken med mad, musik og hygge for hele familien.'
  },
  {
    id: 2,
    title: 'Nyt Stiforløb i Skoven',
    category: 'Natur',
    date: '2024-05-20',
    image: 'https://via.placeholder.com/400x250?text=Skovstier',
    summary: 'Oplev vores nye stiforløb med oplyste gangstier og bænke til afslapning.'
  },
  {
    id: 3,
    title: 'Informationsmøde om Vedtægter',
    category: 'Information',
    date: '2024-06-01',
    image: 'https://via.placeholder.com/400x250?text=Informationsm%C3%B8de',
    summary: 'Deltag i næste uge, hvor bestyrelsen gennemgår opdaterede vedtægter.'
  },
  {
    id: 4,
    title: 'Loppemarked i Bytorvet',
    category: 'Arrangementer',
    date: '2024-07-05',
    image: 'https://via.placeholder.com/400x250?text=Loppemarked',
    summary: 'Ryd op i kælderen og sæt dine ting til salg eller kom og gør et kup.'
  },
  {
    id: 5,
    title: 'Nye Tiltag for Bæredygtighed',
    category: 'Natur',
    date: '2024-05-28',
    image: 'https://via.placeholder.com/400x250?text=B%C3%A6redygtighed',
    summary: 'Vi introducerer affaldssortering og nye genbrugsstationer i området.'
  }
];

function Nyheder() {
  const [news, setNews] = useState(initialNews);
  const [category, setCategory] = useState('Alle');
  const [newsletterEmail, setNewsletterEmail] = useState('');
  const [newsletterStatus, setNewsletterStatus] = useState(null);
  const [sortOrder, setSortOrder] = useState('desc'); // 'asc' or 'desc'
  const [visibleCount, setVisibleCount] = useState(3); // Initial number of news items to show

  const filteredNews = news
    .filter(item => category === 'Alle' || item.category === category)
    .sort((a, b) => {
      if (sortOrder === 'asc') {
        return new Date(a.date) - new Date(b.date);
      } else {
        return new Date(b.date) - new Date(a.date);
      }
    });

  const visibleNews = filteredNews.slice(0, visibleCount);

  const handleNewsletterSubmit = (e) => {
    e.preventDefault();
    if (!newsletterEmail.trim() || !/\S+@\S+\.\S+/.test(newsletterEmail)) {
      setNewsletterStatus('error');
      return;
    }
    // Simulate newsletter signup
    setTimeout(() => {
      setNewsletterStatus('success');
      setNewsletterEmail('');
    }, 1000);
  };

  const loadMoreNews = () => {
    setVisibleCount(prev => prev + 3);
  };

  return (
    <div className="nyheder-container">
      <header className="nyheder-hero">
        <div className="hero-content">
          <h1>Nyheder</h1>
          <p>Hold dig opdateret med de seneste nyheder og arrangementer i Kildeskoven.</p>
          <div className="newsletter-signup">
            <h2>Tilmeld Nyhedsbrev</h2>
            <form onSubmit={handleNewsletterSubmit} className="newsletter-form">
              <input
                type="email"
                placeholder="Din e-mail"
                value={newsletterEmail}
                onChange={(e) => setNewsletterEmail(e.target.value)}
              />
              <button type="submit">Tilmeld</button>
            </form>
            {newsletterStatus === 'success' && <p className="success-msg">Tak for tilmeldingen!</p>}
            {newsletterStatus === 'error' && <p className="error-msg">Ugyldig e-mail. Prøv igen.</p>}
          </div>
        </div>
      </header>

      <div className="filters">
        <div className="category-filters">
          {['Alle', 'Arrangementer', 'Information', 'Natur'].map(cat => (
            <button
              key={cat}
              className={category === cat ? 'active' : ''}
              onClick={() => setCategory(cat)}
            >
              {cat}
            </button>
          ))}
        </div>
        <div className="sort-order">
          <label>Sorter efter dato:</label>
          <select value={sortOrder} onChange={(e) => setSortOrder(e.target.value)}>
            <option value="desc">Nyeste først</option>
            <option value="asc">Ældste først</option>
          </select>
        </div>
      </div>

      <div className="news-grid">
        {visibleNews.map(item => (
          <div className="news-item" key={item.id}>
            <img src={item.image} alt={item.title} />
            <div className="news-content">
              <span className="news-category">{item.category}</span>
              <h3>{item.title}</h3>
              <p className="news-date">{new Date(item.date).toLocaleDateString('da-DK')}</p>
              <p className="news-summary">{item.summary}</p>
              <a href={`/nyheder/${item.id}`} className="read-more">Læs Mere</a>
            </div>
          </div>
        ))}
      </div>

      {visibleCount < filteredNews.length && (
        <div className="load-more-container">
          <button className="load-more-btn" onClick={loadMoreNews}>Indlæs Flere</button>
        </div>
      )}
      <BackToTopButton />
     
    </div>
   

    
  );
  
}

export default Nyheder;
